import request from "@/api/service";

const api = {
   list: '/goods/productCategory/getList',
   categoryList: '/goods/productCategory/getCategoryList',
   create: '/goods/productCategory/create',
   update: '/goods/productCategory/update',
   delete: '/goods/productCategory/delete'
}

export function getList(params) {
   return request({
      url: api.list,
      method: 'POST',
      data: params
   })
}

export function getCategoryList(params) {
   return request({
      url: api.categoryList,
      method: 'POST',
      data: params
   })
}

export function createCategory(params, action) {
   return request({
      url: api[action],
      method: 'POST',
      data: params
   })
}

export function delCategory(query, params = {}) {
   return request({
      url: api.delete + query,
      method: 'POST',
      data: params
   })
}
