import request from "@/api/service";
import qs from 'qs';

const api = {
   page: '/goods/homeMenu/getPage',
   add: '/goods/homeMenu/add',
   edit: '/goods/homeMenu/edit',
   delete: '/goods/homeMenu/delete',
   move: '/goods/homeMenu/move',
   qrCode: '/goods/homeMenu/generate'
}

export function getPage(params) {
   return request({
      url: api.page,
      method: 'GET',
      params
   })
}

export function addColumn(params, action) {
   return request({
      url: api[action],
      method: 'POST',
      data: params
   })
}

export function delColumn(query) {
   return request({
      url: api.delete + query,
      method: 'POST'
   })
}

export function moveColumn(params) {
   return request({
      url: api.move,
      method: 'POST',
      data: qs.stringify(params)
   })
}

export function generateQrCode(params) {
   return request({
      url: api.qrCode,
      method: 'POST',
      data: qs.stringify(params)
   })
}
