<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item">
            <label>标题名称：</label>
            <el-input v-model.trim="params.title" placeholder="标题名称" clearable></el-input>
          </el-row>
          <el-button @click="handleQuery(true)" class="bg-gradient" type="primary" icon="el-icon-search">搜索</el-button>
          <el-button @click="handleQuery(false)" icon="el-icon-refresh-right" plain>重置</el-button>
        </el-row>
      </el-row>
      <el-row class="table-box">
        <el-alert class="width-434" title="最多允许添加8个栏目" type="warning"></el-alert>
        <el-row type="flex" justify="space-between">
          <div></div>
          <div class="right-btn">
            <el-button @click="visible = true; action = 'add'" class="bg-gradient">新建栏目</el-button>
          </div>
        </el-row>
        <el-table v-loading="loading" :data="tableData" :stripe="true" border fit>
          <el-table-column label="序号" width="90">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="图片">
            <template slot-scope="scope">
              <el-image class="width-70" :src="scope.row.imageUrl"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column label="类型">
            <template slot-scope="scope">{{ scope.row.type | filterType(that) }}</template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="handleEdit(scope.row)" type="text">编辑</el-button>
              <el-button v-if="scope.row.sort !== 1" @click="handleMove(scope.row.id, 0)" type="text">上移</el-button>
              <el-button v-if="scope.row.sort !== tableData.length" @click="handleMove(scope.row.id, 1)" type="text">下移</el-button>
              <el-button @click="getQrCode(scope.row.id)" type="text">二维码</el-button>
              <el-button @click="handleDel(scope.row.id)" type="text">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </div>
    <el-dialog title="二维码" width="450px" :visible.sync="qrCodeVisible">
      <div class="qr-code-box">
        <el-image class="width-280" :src="qrCode"></el-image>
      </div>
      <span slot="footer">
        <el-button @click="qrCodeVisible = false">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="新建" :visible.sync="visible" width="550px" :before-close="handleClose">
      <el-form ref="form" :model="ruleForm" :rules="rules">
        <el-form-item label="标题" prop="title">
          <el-input class="width-280" v-model="ruleForm.title" placeholder="标题"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="imageUrl">
          <div @click="visibleImage = true" class="add-image">
            <el-image v-if="ruleForm.imageUrl" :src="ruleForm.imageUrl"></el-image>
            <div v-else><i class="el-icon-plus"></i></div>
          </div>
          <!-- <el-upload
              class="avatar-uploader" ref="cover-uploader" :show-file-list="false"
              action="#" :auto-upload="false" :on-change="handleUpload">
            <img width="178px" v-if="ruleForm.imageUrl" :src="ruleForm.imageUrl">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>-->
        </el-form-item>
        <el-form-item label="类型">
          <el-select class="width-280 m-bottom-10" v-model="ruleForm.type" placeholder="选择分类">
            <el-option v-for="(val, key) in { '1': '分类', '2': '搜索词', '3': '其他页面' }"
                        :key="key" :label="val" :value="key"></el-option>
          </el-select>
        </el-form-item>
        <div class="m-left-40 type-box">
          <el-form-item v-if="ruleForm.type === '1'" prop="categoryId">
            <el-button @click="selectedCategory" class="m-right-5" type="primary">选择分类</el-button>
            <span style="color: #F56C6C">{{ ruleForm.categoryId ? '' : '未选择' }}</span>
          </el-form-item>
          <el-form-item v-if="ruleForm.type === '2'" prop="searchTerm">
            <el-input v-model="ruleForm.searchTerm" class="width-280" placeholder="输入搜索词"></el-input>
          </el-form-item>
          <el-form-item v-if="ruleForm.type === '3'" prop="url">
            <el-input v-model="ruleForm.url" class="width-280" placeholder="输入链接地址"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
    <Cropper :visible="visibleImage" @setImageInfo="getImageInfo" @closeUploadImg="closeUploadImg"/>
    <el-dialog title="选择分类" :visible.sync="categoryVisible" width="550px">
      <el-tree ref="tree" :data="categoryTree" show-checkbox default-expand-all node-key="id"
               :props="{ children: 'children', label: 'name' }" @check="handleCheck"></el-tree>
      <span slot="footer">
         <el-button @click="categoryVisible = false">取消</el-button>
         <el-button type="primary" @click="saveCheckCategory">确定</el-button>
       </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { getPage, addColumn, delColumn, moveColumn, generateQrCode } from "@/api/shop/conf/treasureBox";
import { getList } from '@/api/shop/goods/category';
import { getDict } from "@/common/js/common";
import Cropper from "@/components/local/Cropper";
export default {
  name: 'treasureBox',
  data() {
    const validCategory = (rule, value, callback) => {
      if (this.ruleForm.type === '1' && !value) callback(new Error('分类不能为空！'))
      else callback()
    }
    const validSearchTerm = (rule, value, callback) => {
      if (this.ruleForm.type === '2' && !value) callback(new Error('搜索词不能为空！'))
      else callback()
    }
    const validUrl = (rule, value, callback) => {
      if (this.ruleForm.type === '3' && !value) callback(new Error('跳转链接不能为空！'))
      else callback()
    }
    return {
      crumbs: new Map([
          ['特惠商城'], ['页面配置'], ['首页百宝箱']
      ]),
      that: this,
      loading: false,
      tableData: [],
      total: 0,
      params: {
        limit: 8,
        page: 1,
      },
      action: 'add',
      visible: false,
      ruleForm: {
        id: '',
        title: '',
        type: '1',
        imageUrl: '',
        status: '1',
        categoryId: '',
        searchTerm: '',
        url: ''
      },
      rules: {
        title: [{ required: true, message: '标题不能为空！' }],
        imageUrl: [{ required: true, message: '图片不能为空！' }],
        categoryId: [{ validator: validCategory }],
        searchTerm: [{ validator: validSearchTerm }],
        url: [{ validator: validUrl }]
      },
      categoryVisible: false,
      categoryTree: {},
      categoryId: '',
      qrCodeVisible: false,
      qrCode: '',
      visibleImage: false
    }
  },
  components: {
    Cropper
  },
  computed: {
    ...mapState(['hotelInfo', 'dictData'])
  },
  mounted() {
    getDict('shop-jump-type')
    this.getColumnList()
    this.getCategorise()
  },
  methods: {
    // 获取栏目列表
    getColumnList() {
      const params = { ...this.params, hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId };
      // if (this.hotelInfo.hotelName === '达易住特惠') delete params.hotelId
      getPage(params).then(({ success, records, total }) => {
        if (!success) return
        this.loading = false
        this.total = total
        this.tableData = records
      })
    },
    // 搜索栏目
    handleQuery(bool) {
      this.params.page = 1
      if (bool) return this.getColumnList()
      this.params.title = ''
    },
    // 获取上传图片信息
    getImageInfo({ url }) {
      this.visibleImage = false
      this.ruleForm.imageUrl = url
    },
    // 关闭上传图片会话框
    closeUploadImg() {
      this.visibleImage = false
    },
    // 打开商品分类会话框
    selectedCategory() {
      this.categoryVisible = true
      if (this.action === 'edit') {
        this.$nextTick(() => this.$refs.tree.setCheckedKeys([ this.ruleForm.categoryId ]))
      }
    },
    // 获取商品分类
    getCategorise() {
      const params = { hotelId: this.hotelInfo.id };
      // if (this.hotelInfo.hotelName === '达易住特惠') delete params.hotelId
      getList(params).then(({ success, records }) => {
        if (!success) return;
        this.categoryTree = this.insertAttribute(records)
      })
    },
    // 插入禁选属性
    insertAttribute(categoryTree) {
      categoryTree.forEach(item => {
        if (item.level !== 3) item.disabled = true;
        if (item.hasOwnProperty('children')) this.insertAttribute(item.children)
      })
      return categoryTree
    },
    // 处理分类选中
    handleCheck({ id }) {
      this.categoryId = id
      this.$refs.tree.setCheckedKeys([ id ])
    },
    // 确定保存分类
    saveCheckCategory() {
      this.categoryVisible = false
      Object.assign(this.ruleForm, { categoryId: this.categoryId })
    },
    // 编辑栏目
    handleEdit(row) {
      this.visible = true
      this.action = 'edit'
      for (const k in this.ruleForm) k in row && (this.ruleForm[k] = row[k])
    },
    // 保存栏目
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return
        const params = {
          ...this.ruleForm,
          hotelId: this.hotelInfo.id,
          companyId: this.hotelInfo.storeId
        }
        if (this.action === 'add') delete params.id
        addColumn(params, this.action).then(({ success }) => {
          if (!success) return
          this.getColumnList()
          this.handleClose()
          this.$message({ message: '保存成功！', type: 'success' })
        })
      })
    },
    // 关闭会话框
    handleClose() {
      this.visible = false
    },
    // 删除栏目
    handleDel(id) {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        delColumn(`/${ id }`).then(({ success }) => {
          if (!success) return;
          this.getColumnList()
          this.$message({ message: '删除成功！', type: 'success' })
        })
      }).catch(_ => {})
    },
    // 处理排序
    handleMove(id, step) {
      const params = { id, step }
      moveColumn(params).then(({ success }) => {
        if (!success) return;
        this.getColumnList()
        this.$message({ message: '排序成功！', type: 'success' })
      })
    },
    // 获取二维码
    getQrCode(id) {
      generateQrCode({ id }).then(({ success, records }) => {
        if (!success) return;
        if (!records.qrCode) return this.$message({ message: '缺少二维码地址', type: 'error' })
        else {
          this.qrCodeVisible = true
          this.qrCode = 'data:image/png;base64,' + records.qrCode
        }
      })
    }
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      if (oldVal && newVal.id !== oldVal.id) {
        this.getColumnList()
        this.getCategorise()
      }
    }
  },
  filters: {
    filterType(val, that) {
      if (val && that.dictData['shop-jump-type']) return that.dictData['shop-jump-type'][val] || val
    }
  }
}
</script>
<style scoped lang="scss">
.cont {
  .el-form {
    padding: 0 30px;
    .el-form-item:nth-child(3) { margin-bottom: 0 }
    .add-image {
      width: 120px; height: 120px; border: dashed 1px #c0ccda;
      border-radius: 6px; text-align: center; cursor: pointer;
      margin: 0 0 0 51px;
      .el-icon-plus{ font-size: 22px; color: #8c939d; line-height: 120px }
    }
  }
  .avatar-uploader .avatar-uploader-icon{
    width: 120px; height: 120px; line-height: 120px;
  }
  ::v-deep .el-form-item__error{ margin-left: 50px }
  .type-box{
    ::v-deep .el-form-item__error{ margin-left: 0 }
  }
  .qr-code-box{ text-align: center }
}
</style>
